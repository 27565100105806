import {
    Box,
    Button,
    Group,
    Modal,
    Stack,
    Title,
    type ModalProps,
} from '@mantine/core';
import React, { type FC } from 'react';

interface Props extends ModalProps {
    embedUrl: string;
}

const EmbedDashboardPreviewModal: FC<Props> = ({ embedUrl, ...modalProps }) => {
    return (
        <Modal title={<Title order={4}>Preview embed</Title>} {...modalProps}>
            <Stack>
                <Box sx={{ height: 'calc(100vh - 140px)' }}>
                    <iframe
                        title="embed dashboard preview"
                        src={embedUrl}
                        width={'100%'}
                        height={'100%'}
                        style={{ border: 'none' }}
                    ></iframe>
                </Box>

                <Group position="right" mt="sm">
                    <Button variant="outline" onClick={modalProps.onClose}>
                        Close preview
                    </Button>
                </Group>
            </Stack>
        </Modal>
    );
};

export default EmbedDashboardPreviewModal;
