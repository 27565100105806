import { type SlackChannelProjectMapping } from '@lightdash/common';
import {
    ActionIcon,
    Group,
    Select,
    Stack,
    Title,
    Tooltip,
} from '@mantine/core';
import {
    IconArrowsHorizontal,
    IconDatabase,
    IconHash,
    IconHelpCircle,
    IconPlus,
    IconX,
} from '@tabler/icons-react';
import { differenceWith } from 'lodash';
import { useCallback, useMemo, useState, type FC } from 'react';
import { useProjects } from '../../../hooks/useProjects';
import MantineIcon from '../../common/MantineIcon';

type ChannelProjectMappingsProps = {
    channelOptions: { value: string; label: string }[];
    mappings?: SlackChannelProjectMapping[];
    onChange: (value: SlackChannelProjectMapping[]) => void;
};

const ChannelProjectMappings: FC<ChannelProjectMappingsProps> = ({
    channelOptions,
    mappings,
    onChange,
}) => {
    const { data: projects } = useProjects();
    const [selectedProject, setSelectedProject] = useState<string>();
    const [selectedChannel, setSelectedChannel] = useState<string>();
    const usedChannels = useMemo(
        () => mappings?.map((mapping) => mapping.slackChannelId) ?? [],
        [mappings],
    );

    const projectOptions = useMemo(() => {
        return (
            projects?.map((project) => ({
                value: project.projectUuid,
                label: project.name,
            })) ?? []
        );
    }, [projects]);

    const filteredChannelOptions = useMemo(() => {
        return differenceWith(
            channelOptions,
            usedChannels,
            (channel, used) => channel.value === used,
        );
    }, [channelOptions, usedChannels]);

    const handleAddMapping = useCallback(() => {
        if (selectedProject && selectedChannel) {
            onChange([
                ...(mappings ?? []),
                {
                    projectUuid: selectedProject,
                    slackChannelId: selectedChannel,
                },
            ]);

            setSelectedProject(undefined);
            setSelectedChannel(undefined);
        }
    }, [mappings, onChange, selectedChannel, selectedProject]);

    const handleDeleteMapping = useCallback(
        (index: number) => {
            onChange(mappings?.filter((_, i) => i !== index) ?? []);
        },
        [mappings, onChange],
    );

    return (
        <Stack spacing="sm" w="100%">
            <Group spacing="two" mb="two">
                <Title order={6} fw={500}>
                    AI Bot channel mappings
                </Title>
                <Tooltip
                    variant="xs"
                    multiline
                    maw={250}
                    label="Map which project is associated with which Slack channel. When a user asks a question in a channel, Lightdash will look for the answer in the associated project."
                >
                    <MantineIcon icon={IconHelpCircle} />
                </Tooltip>
            </Group>

            <Stack spacing="sm">
                {mappings?.map(({ projectUuid, slackChannelId }, index) => (
                    <Group
                        key={`${projectUuid}-${slackChannelId}`}
                        spacing="xs"
                        noWrap
                    >
                        <Select
                            size="xs"
                            data={projectOptions}
                            value={projectUuid}
                            icon={<MantineIcon icon={IconDatabase} />}
                            readOnly
                        />

                        <MantineIcon
                            icon={IconArrowsHorizontal}
                            color="gray.5"
                        />

                        <Select
                            size="xs"
                            data={channelOptions}
                            value={slackChannelId}
                            icon={<MantineIcon icon={IconHash} />}
                            readOnly
                        />

                        <ActionIcon
                            size="xs"
                            onClick={() => handleDeleteMapping(index)}
                        >
                            <MantineIcon icon={IconX} />
                        </ActionIcon>
                    </Group>
                ))}

                <Group spacing="xs" noWrap>
                    <Select
                        size="xs"
                        data={projectOptions}
                        value={selectedProject ?? null}
                        allowDeselect
                        clearable
                        searchable
                        placeholder="Select project"
                        icon={<MantineIcon icon={IconDatabase} />}
                        onChange={(value) => {
                            if (value) setSelectedProject(value);
                        }}
                    />

                    <MantineIcon icon={IconArrowsHorizontal} color="gray.5" />

                    <Select
                        size="xs"
                        data={filteredChannelOptions.map((channel) => ({
                            value: channel.value,
                            label: channel.label.replace(/^#/, ''),
                        }))}
                        value={selectedChannel ?? null}
                        allowDeselect
                        clearable
                        searchable
                        placeholder="Select channel"
                        icon={<MantineIcon icon={IconHash} />}
                        onChange={(value) => {
                            if (value) setSelectedChannel(value);
                        }}
                    />

                    <ActionIcon
                        disabled={!selectedChannel || !selectedProject}
                        size="xs"
                        onClick={handleAddMapping}
                    >
                        <MantineIcon icon={IconPlus} />
                    </ActionIcon>
                </Group>
            </Stack>
        </Stack>
    );
};

export default ChannelProjectMappings;
